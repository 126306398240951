@import 'scss/helpers/_globalVars';
@import 'scss/helpers/_mixins';

$BIOS_COUNT: 6;

.bios {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.bios--legacyMembers {
    margin-top: 40px;


    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        margin-bottom: 40px;
        border-top: 1px solid $PLUM_LIGHT;
        opacity: 0.2;
    }

    &::after {
        content: '';
        background: url(/images/heart.svg) no-repeat center center;
        display: block;
        width: 100%;
        height: 40px;
    }

    .bios__item {
        flex: 1;
        margin-bottom: 15px;
        max-width: none;
    }

    .bio__img {
        --bio-image-size: 145px
    }


}

.bios__item {
    margin-bottom: 40px;
}

@include breakpoint(sm) {
    .bios__item {
        max-width: 200px;
    }
}
