@import 'scss/helpers/_globalVars';
@import 'scss/helpers/_mixins';

.tabsNavLink {
    display: flex;
    font-size: 13px;
    font-weight: bold;
    font-family: $FONT_HEADLINE ;
    justify-content: center;
    color: $GREY_MEDIUM;
    background: darken($PLUM, 5);
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none;
}

.tabsNavLink--isActive {
    color: $PLUM;
    background: darken($PLUM_LIGHT, 3);

    .tabsNavLink__icon {
        transform: rotate(90deg);
        transform-origin: center;
    }
}

@include breakpoint(md) {
    .tabsNavLink {
        font-size: 16px;
        color: $GREY_MEDIUM;
        background: #F2f2f2;
    }

    .tabsNavLink--isActive {
        color: $PLUM;
        background: $WHITE;
    }
    .tabsNavLink:not(.tabsNavLink--isActive) {
        &:hover,
        &:focus {
            background: darken($GREY_LIGHT, 1%);
            color: $PLUM;
        }
    }
}

.tabsNavLink__icon {
    transition: transform 0.35s ease-in-out;

    svg {
        display: block;
        fill: #cccccc;
    }
}
