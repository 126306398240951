@import 'scss/helpers/_globalVars';
@import 'scss/helpers/_mixins';

.autocomplete {
    flex: 1;
}

.autocomplete__input {
    background: $WHITE;
    border-radius: 11px 0 0 11px;
    border: 1px solid $GREY_MEDIUM;
    border-right: 0px;
    display: block;
    font-family: $FONT_HEADLINE;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
    padding: 18px; // extra right padding makes room for absolutely positioned button
    width: 100%;
    flex: 1;
}

.autocomplete__list {
    position: absolute;
    background: $WHITE;
    width: 100%;
    margin-top: 10px;
    overflow: hidden;
    border-radius: 11px;
    font-size: 18px;
    z-index: 10;
    box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.35);
}

.autocomplete__action {
    display: block;
    width: 100%;
    padding: 24px 34px;
    color: $BLACK;
    background: none;
    border: 0;
    text-align: left;

    &:hover,
    &:focus {
        background: $GREY_LIGHT;
    }

    b {
        font-weight: bold;
    }
}

.autocomplete--hasSearchVal {
    .autocomplete__input {
        border-radius: 0;
        text-align: center;
        padding-top: 34px;
        padding-bottom: 34px;
    }
}

@include breakpoint(smOnly) {
    .autocomplete__input {
        font-size: $FONT_SIZE_DEFAULT;
    }
}
