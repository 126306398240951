@import 'scss/helpers/_globalVars';
@import 'scss/helpers/_mixins';

.resultMessage {
    padding: 10px 20px 20px 20px;
    color: $GREY_LIGHT;
    margin-bottom: 20px;
}

@include breakpoint(md) {
    .resultMessage {
        color: $BLACK;
    }
}
