.siteInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    margin: 50px auto 0;
}

.siteInfo__fbShit {
    margin-bottom: 40px;
}
