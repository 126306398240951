@import 'scss/helpers/_globalVars';
@import 'scss/helpers/_mixins';

.bio {
    --bio-image-size: 130px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
    width: 100%;
    cursor: pointer;

    &:hover,
    &:focus {
        .bio__img {
            border-color: $WHITE;
        }
    }
}

.bios--legacyMembers {
    .bio {
        pointer-events: none;

    }
}

.bio__legacyText {
    margin-top: 20px;
    font-size: 20px;
    max-width: 643px;
}

.bio__img {
    height: auto;
    width: var(--bio-image-size);
    border-radius: 100%;
    overflow: hidden;
    border: 6px solid $PLUM_DARK;
    margin: 0 auto 10px;
}

.bio__social {
    padding: 0 40px;
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bio__name {
    font-family: $FONT_DISPLAY;
    color: $WHITE;
    font-size: 22px;
    text-align: center;
}

.bio__closeBtn {
    @include BUTTON_RESET;
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: $PLUM_DARK;
    fill: $WHITE;
}

.bio__closeBtnIcon {
    .icon {
        width: 30px;
    }
}

.bio--isActive {
    --bio-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: var(--bio-width);
    height: auto;
    max-height: 95%;
    top: 0;
    padding: 40px 0;
    left: calc(50% - calc(var(--bio-width) / 2));
    right: 0;
    position: fixed;
    z-index: 1;
    overflow: hidden;
    margin: 5% 0;

    background: $PLUM_DARK;
    border-radius: 12px;
    align-items: flex-start;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
    text-align: left;

    &:hover,
    &:focus {
        .bio__img {
            border-color: $PLUM;
        }
    }

    & > * + * {
        margin-top: 10px;
    }

    .bio__img {
        flex: 1 0 auto;
        align-self: center;
        border-color: $PLUM;
    }

    .bio__social {
        margin: 0;
    }

    .bio__socialLinks {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bio__socialLink + .bio__socialLink {
        margin-left: 10px;
    }
    .bio__socialLink {
        display: flex;
    }
}

.bio__heading {
    padding: 0 40px;
    text-align: center;
    width: 100%;
}

.bio__title {
    color: $GREY;
    font-size: 18px;
    font-weight: bold;
    font-family: $FONT_DEFAULT;
    text-align: center;
}

.bio__nickName {
    color: $BLUE;
}

.bio__bd {
    color: $GREY_LIGHT;
    flex: 1;
    overflow: scroll;
    padding: 0 40px;
}

// when a bio is in its active state, we need a placholder
// to prevent the list from overflowing so we dupe the element
// and use it as a placeholder slug
.bio--isSlug {
    .bio__name {
        color: $PLUM_DARK;
    }
    .bio__img {
        position: relative;

        &:before {
            background: $PLUM_DARK;
            opacity: 80%;
            height: 100%;
            width: 100%;
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &:hover,
    &:focus {
        .bio__img {
            border-color: $PLUM_DARK;
        }
    }
}

@include breakpoint(md) {
    .bio__img {
        --bio-image-size: 100px;
    }
    .bio--isActive {
        width: 450px;
        height: auto;
        margin: 60px 0;
        left: calc(50% - 225px);
    }
}

@include breakpoint(lg) {
    .bio__img {
        --bio-image-size: 130px;
    }
}
