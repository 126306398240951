/* ---------------------------------------------------------------------
element styles - any styles that need to be applied to unclassed elements
These styles should be kept to a minimum in order to avoid styles bleeding
into other modules.
------------------------------------------------------------------------ */

html,
body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    position: relative;
}

* {
    box-sizing: border-box;
}

body {
    background-color: $COLOR_PRIMARY;
    width: 100%;
    font-family: $FONT_DEFAULT;
    font-size: $FONT_SIZE_MOBILE;
    line-height: 1.286; // 18px height on standard 14px font-size
    color: $COLOR_TEXT_DEFAULT;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
}

a {
    color: $COLOR_LINK_DEFAULT;
    text-decoration: none;
    cursor: pointer;
}

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

img {
    max-width: 100%;
    vertical-align: top;
}

@media screen and (min-width: 768px) {
    body {
        font-size: $FONT_SIZE_DEFAULT;
    }
}
