@import 'scss/helpers/_globalVars';
@import 'scss/helpers/_mixins';

.supplementalLinks {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .iconLink {
        padding: 10px;
    }
}

.supplementalLinks--searchIsActive {
}

@include breakpoint(xs) {
    .supplementalLinks {
        flex-direction: row;
        justify-content: space-between;
    }
}

@include breakpoint(smOnly) {
    .supplementalLinks {
    }

    .supplementalLinks__link:nth-of-type(2) {
        display: none;
    }
}

@include breakpoint(md) {
    .supplementalLinks--searchIsActive {
        flex-direction: column;
        align-items: flex-start;

        .supplementalLinks__link {
            width: 100%;
        }
        .iconLink {
            padding: 10px 53px;
        }

        .supplementalLinks__link:nth-of-type(2) {
            border-bottom: 1px solid lighten($PLUM, 10);
            padding-bottom: 15px;
        }

        .supplementalLinks__link:last-of-type {
            text-align: center;
        }
    }
}
