// Global Vars

// ----------------------------------------------------------------------------
// Font
// ----------------------------------------------------------------------------
$FONT_DEFAULT: 'Roboto', sans-serif;
$FONT_HEADLINE: 'Montserrat', sans-serif;
$FONT_DISPLAY: 'Bebas Neue', cursive;
$FONT_SIZE_DEFAULT: 16px;
$FONT_SIZE_MOBILE: 13px;

// ----------------------------------------------------------------------------
// Colors
// ----------------------------------------------------------------------------

$COLOR_TEXT_DEFAULT: #4b4b4b;
$COLOR_LINK_DEFAULT: #000000;
$COLOR_TEXT_LIGHT: #ffffff;

$WHITE: #ffffff;
$BLACK: #3f3d3c;
$GREY_LIGHT: #cac9c9;
$GREY_MEDIUM: #8281A1;
$GREY: #b1ada8;
$GREY_DARK: #3f3d3c;
$RED: #bf1c21;
$PLUM_LIGHT: #a59da6;
$PLUM: #463648;
$PLUM_DARK: darken($PLUM, 4);
$BLUE: #2eabe1;

$FACEBOOK_BLUE: #3b5998;
$TWITTER_BLUE: #00a2f9;

$COLOR_PRIMARY: $PLUM;
$COLOR_SECONDARY: $RED;

// ----------------------------------------------------------------------------
// Heading sizes
// ----------------------------------------------------------------------------

$HDG_SIZE: 36px;
$HDG_SIZE: 24px;
$HDG_SIZE: 22px;
$HDG_SIZE: 20px;
$HDG_SIZE: 18px;
$HDG_SIZE: 14px;

// ----------------------------------------------------------------------------
// Global Width
// ----------------------------------------------------------------------------

$GLOBAL_WIDTH: 960px;
