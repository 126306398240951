@import 'scss/helpers/_globalVars';
@import 'scss/helpers/_mixins';

.secondary__heading {
    margin-bottom: 26px;
}

@include breakpoint(smOnly) {
    .secondary--isSticky .secondary__nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: $COLOR_PRIMARY;
        padding: 20px;
        z-index: 1;
        box-shadow: 3px 0 3px 3px rgba(0, 0, 0, 0.5);
    }
}

@include breakpoint(md) {
    .secondary__heading {
        margin-bottom: 53px;
    }
}
