@import 'scss/helpers/_globalVars';
@import 'scss/helpers/_mixins';

.logo {
    font-family: 'Bebas Neue', cursive;
    font-size: 50px;
    color: $WHITE;
    text-align: center;
    line-height: 0.95;
}

.logo__accent {
    position: relative;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        display: block;
        top: 41%;
        left: 50%;
        background: $RED;
        width: 110%;
        height: 42%;
        z-index: -1;
        transform: translate3d(-50%, 0, 0);
    }
}

.logo__seg2 {
    display: block;
}

.logo--isCompact {
    font-size: 22px;
    white-space: nowrap;

    .logo__seg2 {
        display: inline;
    }
}

@include breakpoint(xs) {
    .logo {
        font-size: 60px;
    }

    .logo--isCompact {
        font-size: 24px;
    }
}

@include breakpoint(md) {
    .logo {
        font-size: 72px;
    }

    .logo--isCompact {
        font-size: 31px;

        .logo__seg2 {
            display: inline;
        }
    }
}
